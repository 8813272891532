<template>
    <!-- <v-chart style="height: 100%;" :options="option" /> -->
    <div style="height: 100%; width: 100%">
        <div ref="hourlyHeatMap" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    props: ["hourData", "unit"],
    mounted() {
        this.renderChart();
    },
    data() {
        return {};
    },
    watch: {
        hourData() {
            this.renderChart();
        },
    },
    methods: {
        getMinValue() {
            // HeatMap Data로부터 최대값을 계산함.
            return this.hourData.data.reduce((minVal, item) => {
                if (!item[2]) return minVal;
                return minVal > item[2] ? item[2] : minVal;
            }, 99999999999);
        },
        getMaxValue() {
            // HeatMap Data로부터 최대값을 계산함.
            return this.hourData.data.reduce((maxVal, item) => {
                return maxVal < item[2] ? item[2] : maxVal;
            }, 0);
        },
        renderChart() {
            const chart = echarts.init(this.$refs.hourlyHeatMap);
            let unit = this.unit;
            const option = {
                tooltip: {
                    // position: 'top',
                    position: function(point) {
                        const x = 150;
                        const y = 60;

                        if (point[0] < 600 && point[1] > 40) {
                            return [point[0], point[1] - y];
                        } else if (point[0] < 600 && point[1] < 40) {
                            return [point[0], point[1] + 25];
                        } else if (point[0] > 600 && point[1] > 40) {
                            return [point[0] - x, point[1] - y];
                        } else if (point[0] > 600 && point[1] < 40) {
                            return [point[0] - x, point[1] + 25];
                        } else return "";
                    },
                    trigger: "item",
                    formatter: function(params) {
                        const days = ["토요일", "금요일", "목요일", "수요일", "화요일", "월요일", "일요일"];
                        return `
                            <div style="font-size: 14px;">
                                <span>${params.marker}</span>
                                <span>${days[params.value[1]]}</span>
                                <span>${params.value[0]}시 : </span>
                                <span style="font-weight: bold;">${params.value[2]} ${unit}</span>
                            </div>
                        `;
                    },
                },
                grid: {
                    top: "0%",
                    left: 50,
                    height: "80%",
                    width: "90%",
                },
                xAxis: {
                    type: "category",
                    data: this.hourData.hours, // hours
                    splitArea: {
                        show: true,
                    },
                },
                yAxis: {
                    type: "category",
                    data: this.hourData.days, // days
                    splitArea: {
                        show: true,
                    },
                },
                visualMap: {
                    show: true,
                    // min: 0,
                    min: this.getMinValue(),
                    // max: this.hourData.statsDesc.max,
                    max: this.getMaxValue(),
                    calculable: true,
                    orient: "horizontal",
                    left: "center",
                    bottom: "0%",
                },
                // visualMap: {
                //     type: 'piecewise',  // 구간별 색상 설정을 위해 piecewise 사용
                //     pieces: [
                //         { min: Q3, label: 'Top 25%' },  // 상위 25%
                //         { min: Q2, max: Q3,  label: '50% - 75%' },  // 50% ~ 75%
                //         { min: Q1, max: Q2, label: '25% - 50%' },  // 25% ~ 50%
                //         { max: Q1, label: 'Bottom 25%' }  // 하위 25%
                //     ],
                //     orient: 'horizontal',
                //     left: 'center',
                //     bottom: '0%'
                // },
                series: [
                    {
                        // name: 'Punch Card',
                        type: "heatmap",
                        data: this.hourData.data.map(function(item) {
                            return [item[1], item[0], item[2].toFixed(0) || "-"];
                        }),
                        label: {
                            show: true,
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };

            chart.setOption(option);
            window.addEventListener("resize", () => {
                chart.resize();
            });
        },
    },
};
</script>
